import React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/SEO';

export default function NotFound() {
  return (
    <>
      <Layout>
        <SEO title="Page not found!" />

        <section className="job-hero">
          <div className="wrapper centered">
            <div className="heading-content">
              <h1>Oh, no!</h1>
              <p className="mono post-info">
                The page you are looking for doesn't exist ☹️
              </p>
              <Link to="/" className="button">
                Back to Homepage
              </Link>
            </div>
          </div>
        </section>
      </Layout>
    </>
  );
}
